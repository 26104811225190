import { useState, useEffect } from "react";

function useOnScreen(
  ref,
  rootMargin = "0px",
  threshold = 0,
  unObserve = false,
  base = false
) {
  // State and setter for storing whether element is visible
  const [isIntersecting, setIntersecting] = useState(base);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        // Update our state when observer callback fires
        if (entry.isIntersecting) {
          setIntersecting(true);
        } else {
          setIntersecting(false);
        }

        if (entry.isIntersecting && unObserve) {
          observer.unobserve(ref.current);
        }
      },
      {
        rootMargin,
        threshold,
      }
    );
    if (ref.current) {
      observer.observe(ref.current);
    }
    return () => {
      observer.unobserve(ref.current);
    };
  }, []); // Empty array ensures that effect is only run on mount and unmount

  return isIntersecting;
}

export default useOnScreen;
