import React from "react";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import css from "@styled-system/css";
import posed from "react-pose";

import onSmoothScroll from "../helpers/onSmoothScroll";
import useOnScreen from "../hooks/useOnScreen";

import SEO from "../components/seo";
import Layout from "../components/layout";
import Box from "../components/ui/Box";
import FlexBox from "../components/ui/FlexBox";
import Img from "gatsby-image";
import Button from "../components/ui/Button";
import VideoHero from "../components/VideoHero";
import PageContentContainer from "../components/ui/PageContentContainer";
import ImageCarousel from "../components/ImageCarousel";
import LightboxCarousel from "../components/LightboxCarousel";

import { useStaticQuery, graphql } from "gatsby";

import GlobalContext from "../context/GlobalContext";
import DotSlider from "../components/dot-slider/DotSlider";

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query HomepageQuery {
      contentfulHomepage {
        seoTitle
        title
        slug
        hero {
          __typename
          ... on ContentfulHero {
            title
          }
          buttonText
          title
          videoUrl
          backgroundImage {
            localFile {
              childImageSharp {
                fluid(maxWidth: 1920) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
        imageRow1 {
          title
          localFile {
            childImageSharp {
              fluid(maxWidth: 900) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        videoInCarousel1
        carousel1 {
          title
          localFile {
            childImageSharp {
              fluid(maxWidth: 920) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        contentRow1 {
          json
        }
        imageRow2 {
          title
          localFile {
            childImageSharp {
              fluid(maxWidth: 900) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        contentRow2 {
          json
        }
        carousel {
          title
          localFile {
            childImageSharp {
              fluid(maxWidth: 920) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        contentRow3 {
          json
        }
      }
    }
  `);

  const [lightbox, setLightbox] = React.useState({ open: false, active: 0 });

  const videoRef = React.useRef();
  const heroIsIntersectingContent = useOnScreen(videoRef, "0px", 0, true);
  const heroIsIntersectingHeader = useOnScreen(
    videoRef,
    "0px",
    0.09,
    false,
    true
  );

  const Row1Ref = React.useRef();
  const Row1IsIntersecting = useOnScreen(Row1Ref, "0px", 0.1, true, false);
  const Row2Ref = React.useRef();
  const Row2IsIntersecting = useOnScreen(Row2Ref, "0px", 0.1, true, false);
  const CarouselRef = React.useRef();
  const CarouselIsIntersecting = useOnScreen(
    CarouselRef,
    "0px",
    0.1,
    true,
    false
  );
  const Row3Ref = React.useRef();
  const Row3IsIntersecting = useOnScreen(Row2Ref, "0px", 0.1, true, false);

  const globalContext = React.useContext(GlobalContext);

  React.useEffect(() => {
    if (heroIsIntersectingHeader === true) {
      globalContext.setHeaderColor("light");
    } else {
      globalContext.setHeaderColor("dark");
    }
  }, [heroIsIntersectingHeader]);

  const pageData = data.contentfulHomepage;
  const pageDataBg = pageData.hero.backgroundImage.localFile.childImageSharp;
  const pageDataDotsVideoSrc = pageData.videoInCarousel1;
  const pageDataDotsCarouselItems = pageData.carousel1;
  const pageDataContentRow1 = pageData.contentRow1.json;
  const transformedContentRow1 = documentToReactComponents(pageDataContentRow1);
  const pageDataImageRow2 = pageData.imageRow2.localFile.childImageSharp;
  const pageDataImageRow2Title = pageData.imageRow2.title;
  const pageDataContentRow2 = pageData.contentRow2.json;
  const transformedContentRow2 = documentToReactComponents(pageDataContentRow2);
  const pageDataCarouselItems = pageData.carousel;
  const pageDatacontentRow3 = pageData.contentRow3.json;
  const transformedContentRow3 = documentToReactComponents(pageDatacontentRow3);

  return (
    <Layout style={{ overflow: lightbox.open && "hidden" }}>
      <SEO title={pageData.seoTitle} />
      <VideoHero
        id="hero"
        ref={videoRef}
        videoSrc={pageData.hero.videoUrl}
        heroIsIntersectingContent={heroIsIntersectingContent}
        heroTitle={pageData.hero.title}
        fallbackImage={pageDataBg}
        buttonText={pageData.hero.buttonText}
        buttonAction={() => onSmoothScroll(document.querySelector("#content"))}
      />

      <PageContentContainer id="content" pt={[164, 228, 256, 310]}>
        <AnimatedFlexBox
          ref={Row1Ref}
          pose={Row1IsIntersecting ? "visible" : "hidden"}
          flexDirection={["column", null, "row"]}
          alignItems="center"
          justifyContent={["center", null, "space-around"]}
        >
          <AnimatedBox width={["100%", null, "50%"]}>
            <DotSlider
              items={pageDataDotsCarouselItems}
              videoSrc={pageDataDotsVideoSrc}
            />
          </AnimatedBox>
          <AnimatedBox width={["100%", null, "40%", "45%"]}>
            {transformedContentRow1}
          </AnimatedBox>
        </AnimatedFlexBox>
      </PageContentContainer>
      <AnimatedFlexBox
        ref={Row2Ref}
        pose={Row2IsIntersecting ? "visible" : "hidden"}
        mt={["5rem", "3rem"]}
        flexDirection={["column", null, "row"]}
      >
        <PageContentContainer
          css={css({
            order: [2, null, 1],
          })}
        >
          <AnimatedBox width={["100%", null, "60%"]}>
            {transformedContentRow2}
          </AnimatedBox>
        </PageContentContainer>
        <Box
          width={["100%", null, "70%"]}
          maxWidth="680px"
          position={["relative", null, "absolute"]}
          right={0}
          zIndex={-1}
          css={css({
            order: [1, null, 2],
            alignSelf: ["flex-end", null, "unset"],
            transform: [null, null, "translate(0, -150px)"],
          })}
        >
          <Img
            fluid={pageDataImageRow2.fluid}
            alt={pageDataImageRow2Title}
            durationFadeIn={1000}
            style={{ width: "100%", height: "100%" }}
          />
        </Box>
      </AnimatedFlexBox>
      <PageContentContainer px={[0, "2rem"]} pl={["2rem"]}>
        <AnimatedFlexBox
          ref={CarouselRef}
          pose={CarouselIsIntersecting ? "visible" : "hidden"}
        >
          <AnimatedBox width={["100%", null, null, "85%"]}>
            <ImageCarousel
              items={pageDataCarouselItems}
              setLightbox={setLightbox}
            />
            {lightbox.open && (
              <LightboxCarousel
                lightbox={lightbox}
                setLightbox={setLightbox}
                items={pageDataCarouselItems}
              />
            )}
          </AnimatedBox>
        </AnimatedFlexBox>
      </PageContentContainer>
      <PageContentContainer>
        <AnimatedFlexBox
          ref={Row3Ref}
          pose={Row3IsIntersecting ? "visible" : "hidden"}
        >
          <AnimatedBox>{transformedContentRow3}</AnimatedBox>
        </AnimatedFlexBox>
      </PageContentContainer>
      <FlexBox justifyContent="center" pt={"2rem"} pb={"4rem"}>
        <a
          href="mailto:steven@newblack.io"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Button>Book now</Button>
        </a>
      </FlexBox>
    </Layout>
  );
};

const AnimatedBox = posed(Box)({
  visible: {
    y: 0,
    opacity: 1,
    transition: {
      opacity: { duration: 600, delay: 400 },
      default: { duration: 700, delay: 300, ease: [0.25, 0.1, 0.25, 1] },
    },
  },
  hidden: { y: 75, opacity: 0 },
});

const AnimatedFlexBox = posed(FlexBox)({
  visible: {
    staggerChildren: 200,
  },
  closed: {
    delay: 300,
  },
});

export default IndexPage;
