import styled from "styled-components";
import Box from "./Box";

const PageContentContainer = styled(Box)``;

PageContentContainer.defaultProps = {
  margin: "0 auto",
  px: "2rem",
  pb: "1.45rem",
  maxWidth: "920px",
};

export default PageContentContainer;
