import React from "react";
import styled from "styled-components";
import poser from "react-pose";

import Img from "gatsby-image";

import FlexBox from "./ui/FlexBox";
import Button from "./ui/Button";
import Box from "./ui/Box";

const VideoHero = React.forwardRef(
  (
    {
      videoSrc,
      heroTitle,
      fallbackImage,
      buttonAction,
      buttonText,
      heroIsIntersectingContent,
    },
    ref
  ) => (
    <>
      <VideoWrapper ref={ref}>
        <FlexBox
          position="absolute"
          width={"100%"}
          height={"100vh"}
          alignItems="center"
          justifyContent="center"
        >
          <Img
            fluid={fallbackImage.fluid}
            alt="test"
            durationFadeIn={1000}
            style={{ width: "100%", height: "100%" }}
          />
          <VideoBG
            src={`${videoSrc}?loop=1&background=1`}
            frameborder="0"
            allow="autoplay; fullscreen"
            webkitallowfullscreen
            mozallowfullscreen
            allowfullscreen
          />
        </FlexBox>
      </VideoWrapper>
      <GradientOverlay />
      {heroIsIntersectingContent && (
        <VideoHeroContentWrapper>
          <AnimatedH1
            className={"text__hero-heading"}
            initialPose="hidden"
            pose={heroIsIntersectingContent ? "visible" : "hidden"}
          >
            {heroTitle}
          </AnimatedH1>
          <Box my={["1rem", "2rem"]}>
            <AnimatedButton
              light
              onClick={buttonAction}
              initialPose="hidden"
              pose={heroIsIntersectingContent ? "visible" : "hidden"}
            >
              {buttonText}
            </AnimatedButton>
          </Box>
        </VideoHeroContentWrapper>
      )}
    </>
  )
);

const VideoWrapper = styled(FlexBox)`
  position: relative;
  width: 100%;
  height: 100vh;
  z-index: -1;
  pointer-events: none;
  overflow: hidden;
`;

const VideoBG = styled("iframe")`
  width: 100vw;
  height: 56.25vw;
  /* Given a 16:9 aspect ratio, 9/16*100 = 56.25 */
  min-height: 100vh;
  min-width: 177.77vh;
  /* Given a 16:9 aspect ratio, 16/9*100 = 177.77 */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const VideoHeroContentWrapper = styled(FlexBox)`
  padding-left: 2rem;
  padding-right: 2rem;
  z-index: 3;
`;

VideoHeroContentWrapper.defaultProps = {
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  height: "100%",
  width: "100%",
  position: "absolute",
  top: 0,
  left: 0,
};

const AnimatedH1 = poser.h1({
  visible: {
    y: 0,
    opacity: 1,
    transition: {
      opacity: { duration: 1200, delay: 1000 },
      default: { duration: 1400, delay: 800, ease: [0.25, 0.1, 0.25, 1] },
    },
  },
  hidden: { y: 39, opacity: 0 },
});

const AnimatedButton = poser(Button)({
  visible: {
    y: 0,
    opacity: 1,
    transition: {
      opacity: { duration: 1200, delay: 1000 },
      default: { duration: 1400, delay: 800, ease: [0.25, 0.1, 0.25, 1] },
    },
  },
  hidden: { y: -63, opacity: 0 },
});

const GradientOverlay = styled("div")`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  /* z-index: -1; */
  background-image: linear-gradient(
    225deg,
    rgba(0, 173, 187, 0) 0%,
    rgba(0, 173, 187, 0.15) 100%
  );
`;

export default VideoHero;
