import styled from "styled-components";

const Button = styled("button")`
  border: 1px solid var(--color-primary);
  background: transparent;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.84px;
  line-height: 15px;
  text-transform: uppercase;
  text-align: center;
  padding: 1.25rem 3.75rem;
  cursor: pointer;
  color: ${props =>
    props.light ? "var(--color-light)" : "var(--color-primary)"};
  transition: all 300ms var(--ease-bezier);
  &:hover {
    background-color: var(--color-primary);
    color: var(--color-light);
  }
  &:focus {
    outline: 0;
    border-width: 2px;
  }
`;

export default Button;
