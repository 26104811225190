import React from "react";
import styled from "styled-components";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Img from "gatsby-image";

const ImageCarousel = ({ items, setLightbox }) => {
  var carouselSettings = {
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    adaptiveHeight: true,
    initialSlide: 0,
    padding: "100px",
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          infinite: true,
          arrows: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          initialSlide: 2,
          arrows: true,
        },
      },
      {
        breakpoint: 598,
        settings: {
          slidesToShow: 1.1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };

  const setActiveSlide = index => {
    setLightbox({ active: index, open: true });
  };

  return (
    <Carousel {...carouselSettings}>
      {items.map((item, index) => (
        <div key={index} onClick={() => setActiveSlide(index)}>
          <Img
            key={index}
            fluid={{
              ...item.localFile.childImageSharp.fluid,
              aspectRatio: 1.4,
            }}
            alt={item.title}
            durationFadeIn={1000}
            style={{ width: "100%" }}
          />
        </div>
      ))}
    </Carousel>
  );
};

const Carousel = styled(Slider)`
  .slick-slide {
    padding: 0 8px;
    box-sizing: border-box;
    height: inherit;
  }

  .slide-track {
    display: flex;
  }

  /* Fix external margins */
  .slick-list {
    /* margin: 0 -8px; */
    position: relative;
    /* margin-left: 35px;
    margin-right: 35px; */
    @media screen and (min-width: 600px) {
      margin-left: 50px;
      margin-right: 50px;
    }

    @media screen and (min-width: 1080px) {
      margin-left: -8px;
      margin-right: -8px;
    }
  }
  .slick-slider {
    overflow: hidden;
  }

  .slick-arrow {
    display: flex;
    justify-content: center;
    width: 28px;
    height: 28px;
    @media screen and (min-width: 600px) {
      width: 42px;
      height: 42px;
    }
    border-radius: 50%;
    border: 1px solid #dfdfdf;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;
    &::before {
      content: "";
      box-sizing: border-box;
      height: 9.25px;
      width: 9.25px;
      display: block;
      transition: border-color 300ms var(--ease-bezier);
    }

    &:hover {
      &::before {
        border-color: #00adbb;
      }
    }
  }

  /* Move arrows on top of images */
  .slick-prev {
    left: 0px;
    @media screen and (min-width: 1080px) {
      left: -80px;
    }
    &::before {
      margin-left: 10px;
      @media screen and (min-width: 600px) {
        margin-left: 15px;
      }
      border-top: 3px solid #ebebeb;
      border-left: 3px solid #ebebeb;
      transform: rotate(-45deg);
    }
  }
  .slick-next {
    right: 0px;
    @media screen and (min-width: 1080px) {
      right: -80px;
    }
    &::before {
      margin-left: 8px;
      @media screen and (min-width: 600px) {
        margin-left: 15px;
      }
      border-top: 3px solid #ebebeb;
      border-right: 3px solid #ebebeb;
      transform: rotate(45deg);
    }
  }
`;
export default ImageCarousel;
