import styled from "styled-components";
import PuraVidaLogo from "../../images/logo.svg";

const Logo = styled(PuraVidaLogo)`
  width: 80px;
  @media screen and (min-width: 768px) {
    width: 100px;
  }
  #puravida {
    fill: ${props => (props.themecolor === "dark" ? "#000000" : "#ffffff")};
    transition: fill 1500ms var(--ease-bezier);
  }
`;

Logo.defaultProps = {
  width: "100px",
};

export default Logo;
