import React from "react";
import PageContentContainer from "./ui/PageContentContainer";
import FlexBox from "./ui/FlexBox";
import Box from "./ui/Box";

import FooterLogo from "../images/PuraVidaFooter.svg";
import FooterWaves from "../images/WavesFooter.svg";

const Footer = () => {
  return (
    <PageContentContainer>
      <FlexBox justifyContent={["center", "space-between"]}>
        <Box display={["none", "block"]}>
          <FooterLogo />
        </Box>
        <Box>
          <FooterWaves />
        </Box>
      </FlexBox>
    </PageContentContainer>
  );
};

export default Footer;
