import React from "react";
import styled from "styled-components";
import Img from "gatsby-image";
import Slider from "react-slick";
import FlexBox from "./ui/FlexBox";
import Close from "../images/close-icon.svg";
import useLockBodyScroll from "../hooks/useLockBodyScroll";
import posed from "react-pose";

const LightboxCarousel = ({ lightbox, setLightbox, items }) => {
  const closeLightBox = () => {
    setLightbox({ ...lightbox, open: false });
  };

  useLockBodyScroll();

  const carouselSettings = {
    initialSlide: lightbox.active,
  };
  return (
    <>
      <Animated
        pose={lightbox.open ? "visible" : "hidden"}
        justifyContent="center"
        alignItems="center"
        width={"100%"}
        height={"100%"}
        zIndex={5}
        bg="rgba(255,255,255,0.8)"
        opacity={0}
        position="fixed"
        left="0px"
        top="0px"
        overflow={"hidden"}
      >
        <CloseIcon onClick={closeLightBox}>Close</CloseIcon>
        <Carousel {...carouselSettings}>
          {items.map((item, index) => (
            <Img
              key={index}
              fluid={{
                ...item.localFile.childImageSharp.fluid,
                aspectRatio: 1.33,
              }}
              alt={item.title}
              durationFadeIn={1000}
              style={{ width: "100%" }}
            />
          ))}
        </Carousel>
      </Animated>
    </>
  );
};

const Animated = posed(FlexBox)({
  visible: {
    opacity: 1,
  },
  hidden: {
    opacity: 0,
  },
});

const Carousel = styled(Slider)`
  width: 100%;
  display: block;
  z-index: 10;
  opacity: 1;

  .slick-slider {
    display: block;
  }

  .slick-list {
    width: 100%;
    @media screen and (min-width: 920px) {
      width: 85%;
    }
    max-width: 920px;
    left: 50%;
    transform: translate3d(-50%, 0, 0) !important;
  }

  .slick-arrow {
    display: flex;
    justify-content: center;
    position: absolute;
    width: 28px;
    height: 28px;
    @media screen and (min-width: 600px) {
      width: 42px;
      height: 42px;
    }
    border-radius: 50%;
    border: 1px solid #dfdfdf;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
    &::before {
      content: "";
      box-sizing: border-box;
      height: 9.25px;
      width: 9.25px;
      @media screen and (min-width: 600px) {
        height: 14px;
        width: 14px;
      }
      display: block;
      transition: border-color 300ms var(--ease-bezier);
    }

    &:hover {
      &::before {
        border-color: #00adbb;
      }
    }
  }

  /* Move arrows on top of images */
  .slick-prev {
    left: 8px;
    @media screen and (min-width: 600px) {
      left: 16px;
    }
    &::before {
      margin-left: 10px;
      @media screen and (min-width: 600px) {
        margin-left: 15px;
      }
      border-top: 3px solid #ebebeb;
      border-left: 3px solid #ebebeb;
      transform: rotate(-45deg);
    }
  }
  .slick-next {
    right: 8px;
    @media screen and (min-width: 600px) {
      right: 16px;
    }
    &::before {
      margin-left: 8px;
      @media screen and (min-width: 600px) {
        margin-left: 10px;
      }
      border-top: 3px solid #ebebeb;
      border-right: 3px solid #ebebeb;
      transform: rotate(45deg);
    }
  }
`;

const CloseIcon = styled(Close)`
  position: absolute;
  right: 1rem;
  top: 1rem;
  width: 30px;
  height: 30px;
  cursor: pointer;

  &:hover {
    path {
      fill: var(--color-primary);
    }
  }
`;

export default LightboxCarousel;
