import React from "react";
import styled from "styled-components";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Img from "gatsby-image";

const DotSlider = ({ items, videoSrc }) => {
  var carouselSettings = {
    infinite: true,
    speed: 500,
    dots: true,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    initialSlide: 0,
    padding: "0px",
    autoplay: true,
    autoplaySpeed: 7000,
  };

  return (
    <Carousel {...carouselSettings}>
      {videoSrc ? (
        <div style={{ position: "relative" }}>
          <iframe
            title="video"
            src={`${videoSrc}?loop=1&background=1`}
            frameBorder="0"
            allow="autoplay; fullscreen"
            height="520px"
            width="100%"
            style={{
              position: "relative",
              zIndex: -1,
            }}
          />
        </div>
      ) : null}
      {items.map((item, index) => (
        <div key={index}>
          <Img
            key={index}
            fluid={{
              ...item.localFile.childImageSharp.fluid,
              aspectRatio: 0.9,
            }}
            alt={item.title}
            durationFadeIn={1000}
            style={{ width: "100%" }}
          />
        </div>
      ))}
    </Carousel>
  );
};

const Carousel = styled(Slider)`
  .slick-slide {
    padding: 0 2px;
    box-sizing: border-box;
    height: inherit;
  }

  .slide-track {
    display: flex;
  }

  /* Fix external margins */
  .slick-list {
    position: relative;
  }

  .slick-slider {
    overflow: hidden;
  }

  .slick-dots {
    position: absolute;
    bottom: 20px;

    li {
      width: 8px;
      height: 8px;
    }

    li button:before {
      opacity: 1;
      color: transparent;
      border: 1px solid white;
      width: 8px;
      height: 8px;
      line-height: unset;
      border-radius: 5px;
    }

    li.slick-active button:before {
      border: 1px solid white;
      background-color: white;
      opacity: 1;
      color: transparent;
      line-height: unset;
      border-radius: 5px;
    }
  }
`;
export default DotSlider;
