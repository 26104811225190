import React from "react";
import styled from "styled-components";
import FlexBox from "../ui/FlexBox";
import Logo from "../ui/Logo";
// import NavItem from "./NavItem";
import { Link } from "gatsby";
import GlobalContext from "../../context/GlobalContext";

const Header = () => {
  const globalContext = React.useContext(GlobalContext);

  return (
    <HeaderWrapper>
      <Gradient backgroundGradient={globalContext.headerColor} />
      {/* <FlexBox as="nav" display={["none", null, "flex"]}>
        <NavItem
          to="/"
          mr={[4, null, null, "4rem"]}
          themecolor={globalContext.headerColor}
        >
          Our Catamaran
        </NavItem>
        <NavItem to="/" themecolor={globalContext.headerColor}>
          Trips & Routes
        </NavItem>
      </FlexBox> */}
      <FlexBox mx={[5, null, null, "5rem"]}>
        <Link to="/">
          <Logo themecolor={globalContext.headerColor} />
        </Link>
      </FlexBox>
      {/* <FlexBox as="nav" display={["none", null, "flex"]}>
        <NavItem
          to="/"
          mr={[4, null, null, "4rem"]}
          themecolor={globalContext.headerColor}
        >
          Book your dream
        </NavItem>
        <NavItem to="/" themecolor={globalContext.headerColor}>
          Contact
        </NavItem>
      </FlexBox> */}
    </HeaderWrapper>
  );
};

const HeaderWrapper = styled(FlexBox)``;

HeaderWrapper.defaultProps = {
  as: "header",
  justifyContent: "center",
  alignItems: "center",
  height: 132,
  position: "fixed",
  width: "100%",
  top: 0,
  left: 0,
  right: 0,
  zIndex: 5,
  padding: "0px 1.0875rem",
};

const Gradient = styled(FlexBox)`
  background: ${prop =>
    prop.backgroundGradient === "dark"
      ? "linear-gradient(0deg, rgba(255,255,255,0) 0%, #FFFFFF 70.25%);"
      : "linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.3) 100%)"};
  z-index: -1;
`;

Gradient.defaultProps = {
  position: "fixed",
  width: "100%",
  height: "251px",
  top: 0,
  left: 0,
};

export default Header;
