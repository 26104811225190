import styled from "styled-components";
import { flexbox } from "styled-system";
import propTypes from "@styled-system/prop-types";
import Box from "./Box";

const FlexBox = styled(Box)(flexbox);

FlexBox.propTypes = {
  ...propTypes.layout,
  ...propTypes.space,
  ...propTypes.color,
  ...propTypes.flexbox,
};

FlexBox.defaultProps = {
  display: "flex",
};
export default FlexBox;
