import styled from "styled-components";
import propTypes from "@styled-system/prop-types";
import { space, layout, color, position } from "styled-system";

const Box = styled("div")(
  { boxSizing: "border-box" },
  space,
  layout,
  color,
  position
);

Box.propTypes = {
  ...propTypes.layout,
  ...propTypes.space,
  ...propTypes.color,
  ...propTypes.position,
};

export default Box;
